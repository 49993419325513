// src/pages/AppleClassical.js
import React, { useEffect, useState, useRef } from 'react';

const AppleClassical = () => {
  const [data, setData] = useState(null);
  const [visibleSongs, setVisibleSongs] = useState(20);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const loaderRef = useRef(null);

  useEffect(() => {
    fetchDataAndRender();
	updateDocumentTitle();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.1,
    });
    const currentLoaderRef = loaderRef.current;

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loaderRef]);

  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('https://whatshub.top/api/classic/'); 
      const jsonData = await response.json();
      setAllSongs(jsonData.songs); 
      setData(jsonData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const openSearchPlatform = (platform, song) => {
    if (!selectedSong) return;

    const songQuery = encodeURIComponent(`${selectedSong.songName} ${selectedSong.artistName}`);
    const searchUrl =
      platform === 'youtubeMusic'
        ? `https://music.youtube.com/search?q=${songQuery}`
        : platform === 'youtube'
        ? `https://youtube.com/results?search_query=${songQuery}`
        : `https://open.spotify.com/search/${songQuery}`;

    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isiOS) {
      window.location.assign(searchUrl);
    } else {
      window.open(searchUrl, '_blank');
    }
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisibleSongs((prevVisible) => prevVisible + 20);
    }
  };

  const handleImageClick = (song) => {
    setSelectedSong(song);
  };

  const handleButtonClick = (platform) => {
    openSearchPlatform(platform, selectedSong);
    setSelectedSong(null);
  };

  const renderChart = () => {
    if (!data) return null;
    return allSongs.slice(0, visibleSongs).map((song, index) => (
      <div key={index} className="song">
        <div>
          <img
            src={song.imgUrl}
            alt={song.songName}
            loading="lazy"
            onClick={() => handleImageClick(song)}
          />
          <p>
            {index + 1}. {song.songName}
            <br />- {song.artistName}
          </p>
        </div>
        {selectedSong && selectedSong === song && (
          <div className="search-options">
            <button onClick={() => handleButtonClick('spotify')}>
              <img src="spotify.png" alt="Spotify" />
            </button>
            <button onClick={() => handleButtonClick('youtubeMusic')}>
              <img src="ytm.png" alt="YouTube Music" />
            </button>
            <button onClick={() => handleButtonClick('youtube')}>
              <img src="youtube.png" alt="YouTube" />
            </button>
          </div>
        )}
      </div>
    ));
  };
    const updateDocumentTitle = () => {
    document.title = "Apple Classical chart - MusicBox";
  };
  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '75px' }}>
        {renderChart()}
      </div>
      <div ref={loaderRef}></div>
    </div>
  );
};

export default AppleClassical;