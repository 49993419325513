// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Billboard200 from './pages/Billboard200';
import HomePage from './pages/HomePage';
import Hot100 from './pages/Hot100';
import Artist100 from './pages/Artist100';
import Global200 from './pages/Global200';
import Nethot from './pages/Nethot';
import Netartist from './pages/Netartist';
import QQHits from './pages/QQHits';
import Itunestop from './pages/Itunestop';
import ItunesCN from './pages/ItunesCN';
import AppleClassical from './pages/AppleClassical';
import './index.css'; 
import About from './pages/About';
import NotFound from './pages/NotFound';
const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/200" element={<Billboard200 />} />
        <Route path="/hot100" element={<Hot100 />} />
        <Route path="/artist100" element={<Artist100 />} />
        <Route path="/global200" element={<Global200 />} />
		<Route path="/about" element={<About />} />
		<Route path="/nethot" element={<Nethot />} />
		<Route path="/netartist" element={<Netartist />} />
		<Route path="/qqhits" element={<QQHits />} />
		<Route path="/itops" element={<Itunestop />} />
		<Route path="/itopcn" element={<ItunesCN />} />
		<Route path="/iclassical" element={<AppleClassical />} />
		<Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};
export default App;
