import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const getPageName = () => {
    switch (location.pathname) {
      case '/200':
        return 'Billboard200';
      case '/':
        return 'MusicBox';
      case '/hot100':
        return 'Hot100';
      case '/artist100':
        return 'Artist100';
      case '/global200':
        return 'Global200';
      case '/nethot':
        return 'NeteaseHits';
      case '/netartist':
        return 'NeteaseArtist';
      case '/qqhits':
        return 'QQHits';
      case '/itops':
        return 'iTunesTop';
      case '/itopcn':
        return 'iTunesCN';
      case '/iclassical':
        return 'Classical';
      case '/about':
        return 'About';
      default:
        return '';
    }
  };

  return (
    <nav ref={navRef} className="navbar">
      <Link to="/" className="navbar-logo">
        <img src="/logo.png" alt="Logo" />
        <h1 className="page-name">{getPageName()}</h1>
      </Link>
      <div className="navbar-toggle" onClick={toggleDropdown}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`navbar-links ${dropdownOpen ? 'active' : ''}`}>
        <li onClick={closeDropdown}>
          <Link to="/">            
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/hot100">
            <img src="bb1.png" alt="bb" style={{ width: '15px', height: '15px' }} />
            Hot100
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/200">
            <img src="bb.png" alt="bb" style={{ width: '15px', height: '15px' }} />
            200
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/artist100">
            <img src="bb2.png" alt="bb" style={{ width: '15px', height: '15px' }} />
            Artist
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/global200">
            <img src="bb3.png" alt="bb" style={{ width: '15px', height: '15px' }} />
            Global
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/nethot">
            <img src="ne.png" alt="ne" style={{ width: '15px', height: '15px' }} />
            Hits
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/netartist">
            <img src="ne.png" alt="ne" style={{ width: '15px', height: '15px' }} />
            Artist
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/qqhits">
            <img src="qq.png" alt="qq" style={{ width: '15px', height: '15px' }} />
            Hits
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/itops">
            <img src="it.png" alt="it" style={{ width: '15px', height: '15px' }} />
            Top
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/itopcn">
            <img src="it.png" alt="it" style={{ width: '15px', height: '15px' }} />
            TopCN
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/iclassical">
            <img src="ac.png" alt="it" style={{ width: '15px', height: '15px' }} />
            Classical
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/about">
            <img src="ab.png" alt="ab" style={{ width: '15px', height: '15px' }} />
            About
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="https://whatshub.top">
            <img src="00.png" alt="ab" style={{ width: '15px', height: '15px' }} />
            Yfamily
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Navbar;